// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; //, Navigate
import ProductList from './components/ProductList';
import ProductDetail from './components/ProductDetail';
import Cart from './components/Cart';
import Navbar from './components/Navbar';
import About from './components/About'; // Import the new About component
import './styles.css';

const App = () => {
  return (
    <Router>
      <Navbar /> {/* Include the Navbar with the new link */}
      <Routes>
        <Route path="/" element={<ProductList />} />
        <Route path="/about" element={<About />} /> {/* New About page route */}
        <Route path="/product/:id" element={<ProductDetail />} />
        <Route path="/cart" element={<Cart />} />
        {/* <Route path="*" element={<Navigate to="/" />} /> */}
      </Routes>
    </Router>
  );
};

export default App;
