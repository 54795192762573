// src/components/ProductCard.js
import React from 'react';
import { Link } from 'react-router-dom';

const ProductCard = ({ product, price, sizes }) => {
  return (
    <div className="product-card"> {/* Card structure */}
      <Link to={`/product/${product.id}`}> {/* Link to product detail page */}
        <img src={product.image} alt={product.name} className="product-card-image" /> {/* Product image */}
        <h3>{product.name}</h3> {/* Product name */}
        <p>Starting at: ₹{price}</p> {/* Display the minimum price */}
        {/* <p>Available Sizes: {sizes}</p> Display the list of available sizes */}
      </Link>
    </div>
  );
};

export default ProductCard;
