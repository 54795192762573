import React from 'react';
import { products } from '../products'; // Import the product data
import ProductCard from './ProductCard'; // Product card component
import ProductImageSlider from './ProductImageSlider'; // The new image slider component


const ProductList = () => {
  return (
    <div className="product-list">
      <h1 className='h1cl'>Experience Nature’s Purest Touch – Embrace Organic,     
      🌿Embrace Life! 🌱</h1>
      <ProductImageSlider products={products} /> {/* Add the image slider before the product grid */}
      <br>
      </br>
      <div className="product-list-grid"> {/* Grid layout for product cards */}
        {products.map((product) => (
          <ProductCard
            key={product.id}
            product={product}
            price={product.basePrice} // Minimum price
            // sizes={product.sizes.map((s) => s.size).join(', ')} // List of sizes
          />
        ))}
      </div>
    </div>
  );
};

export default ProductList;
