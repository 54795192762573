// src/components/ProductImageSlider.js
import React from 'react';
import Slider from 'react-slick'; // Import the carousel component
import 'slick-carousel/slick/slick.css'; // Carousel CSS
import 'slick-carousel/slick/slick-theme.css'; // Carousel theme CSS

const ProductImageSlider = ({ products }) => {
  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Loop infinitely
    speed: 500, // Transition speed
    slidesToShow: 1, // Number of slides to show at a time
    slidesToScroll: 1, // Number of slides to scroll
    autoplay: true, // Autoplay the slider
    autoplaySpeed: 3000, // Speed for autoplay
  };

  return (
    <Slider {...settings}> {/* Carousel with specified settings */}
      {products.map((product) => (
        <div key={product.id}> {/* Slider item */}
          <img
            src={product.image} // Display the product's main image
            alt={product.name}
            className="slider-image"
          />
        </div>
      ))}
    </Slider>
  );
};

export default ProductImageSlider;
