// src/components/About.js
import React from 'react';

const About = () => {
  return (
    
    <div className="container">
      
      <div className="top-div">
      <h1>About Us</h1>
      <img src="\images\tree.png" alt="Logo" className="aboutlogo" /> 
      <p className='word'>Organic Products made with LOVE that's what you get from US</p>
      <p className='aboutmaintext'> 
        Welcome to our store! We offer a wide variety of products for you to explore and purchase. Our goal
        is to provide the best shopping experience with high-quality products and excellent customer service.
       
      </p>
      </div>
      <div className='aboutsecondtext'>
      <p>
        If you have any questions Please feel free to reach out to us. <br/>Thank you for choosing our store!😊
      </p></div>
        
        <div className='bottom-container'>
          <div className='side-div'>
            <p className='addrestext'>
              Name :  Servashresta Organics <br/><br/>
              Phone: 8387007800 <br/>
            
              Address:Guligenahalli,Sira taluk,Tumkur-572137<br/><br/>
              Experience Nature’s Purest Touch – Embrace Organic, Embrace Life!    and excellent customer service.
          </p>
          </div>
        <div className='side-div1'>
        <p>locate us at :</p>
      <iframe title='gmapframe'src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15528.563794419757!2d77.09263246733357!3d13.341507110553207!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb02f9a448a4607%3A0x8767db4bdd0ad2c9!2sTumkur%20KSRTC%20Bus%20Stand!5e0!3m2!1sen!2sin!4v1714967592291!5m2!1sen!2sin" 
          width="200" height="150" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
          </iframe>  
          </div>
          </div>
          
    </div>
  );
};

export default About;
