// src/components/Cart.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getCartFromLocalStorage } from '../utils';
import UserInfoModal from './UserInfoModal';
import axios from 'axios'; // Use Axios for HTTP requests
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

// src/components/Cart.js

// Define a utility function to format cart details
export const formatCartItems = (cart) => {
  if (!cart || cart.length === 0) { // Handle empty cart cases
    return 'No items in cart.';
  }

  return cart.map((item) => {
    const total = item.price * item.quantity;
    return `\t Product: ${item.name}
                Size: ${item.size}
                Package: ${item.package}
                Quantity: ${item.quantity}
                Total: ₹${total.toFixed(2)}
                `; // Format cart details
  }).join('\n\n'); // Separate each item with a newline
};


const Cart = () => {
  const [cart, setCart] = useState(getCartFromLocalStorage()); // Cart state
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

  

  useEffect(() => {
    const storedCart = getCartFromLocalStorage(); // Fetch cart data from local storage
    setCart(storedCart); // Set the cart state
  }, []); // Re-run when cart changes
  
  

  

  

  const increaseQuantity = (index) => {
    const updatedCart = [...cart];
    updatedCart[index].quantity += 1; // Increase quantity
    setCart(updatedCart); // Update cart state
  };

  const decreaseQuantity = (index) => {
    const updatedCart = [...cart];
    if (updatedCart[index].quantity > 1) {
      updatedCart[index].quantity -= 1; // Decrease quantity
    } else {
      removeFromCart(index); // If quantity is one, remove from cart
    }
    setCart(updatedCart);
  };
  const removeFromCart = (index) => {
    const updatedCart = cart.filter((_, i) => i !== index); // Remove an item
    setCart(updatedCart); // Update cart state
  };

  const calculateTotalPrice = () => {
    return cart.reduce(
      (total, product) => total + product.price * product.quantity,
      0
    );
  };

 

  const openModal = () => {
    if (!cart || cart.length === 0) { // Handle empty cart cases
      return window.alert('No items in cart.');
    }
    else{
    setIsModalOpen(true); // Open the modal
  }
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const clearCart = () => {
    setCart([]); // Clear the cart state
    localStorage.removeItem('cart'); // Clear cart from local storage
  };

  const handleModalSubmit = async (userInfo) => {
    try {
      // Send the collected information to the backend to send email
      // const response = await axios.post('/send-email', userInfo);

      // console.log("Email response:", response.data); // Log the server response
      // Clear the cart after booking
      alert("Booking successful!"); // Notify the user
      clearCart(); 
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to book order. Please try again later.');
    }
  };
  

  
  return (
    
    <div className="cart">
    <h1>Item's In Your  Cart</h1>
    {cart.length === 0 ? (
      <p>Your cart is empty.You can shop <Link to="/" >Here</Link></p>
    ) : (
      <div className="cart-items"> {/* Container for cart items */}
        {cart.map((product, index) => (
          <div key={index} className="cart-item"> {/* Cart item */}
            <div className="cart-item-image-wrapper"> {/* Product image */}
              <img
                src={product.image}
                alt={product.name}
                className="cart-item-image"
              />
            </div>
            
            <div className="cart-item-details"> {/* Product details */}
              <h3>{product.name}</h3>
              <p>Size: {product.size}</p> {/* Product size */}
              <p>Package: {product.package}</p> {/* Product package type */}
              <p>
                ₹{product.price} x {product.quantity} = ₹
                {product.price * product.quantity} {/* Price calculation */}
              </p>
            </div>
            <div className="cart-item-actions"> {/* Cart item actions */}
              <button onClick={() => increaseQuantity(index)}>+</button>
              <button onClick={() => decreaseQuantity(index)}>-</button>
              <button onClick={() => removeFromCart(index)}>
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </div>
        ))}
      </div>
    )}
    <p>Total: ₹{calculateTotalPrice()}</p> {/* Total price */}


    <button className="add-to-cart-button" onClick={clearCart}>Clear Cart</button> {/* Clear the cart */}


    <button className="add-to-cart-button" onClick={openModal} onCancel={closeModal}>Book Order</button> {/* Open the modal */}

      {/* Include the modal, passing the necessary props */}
      <UserInfoModal
        isOpen={isModalOpen} // Modal visibility state
        onClose={closeModal} // Close the modal
        onSubmit={handleModalSubmit} // Handle submission
        clearCart={clearCart} // Pass cart data to the modal
        cart={cart}
        final_total={calculateTotalPrice()}

      />

  </div>
);
};

export default Cart;