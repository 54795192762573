// src/components/UserInfoModal.js
import React, { useState } from 'react';
import './UserInfoModal.css';

import { formatCartItems } from './Cart'; // Import the utility function to format cart items

const UserInfoModal = ({ isOpen, onClose, onSubmit, cart,final_total }) => {
  const [name_customer, setname_customer] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [comments, setComments] = useState('');

  const handleSubmit = async () => {
    const cartDetails = formatCartItems(cart); // Format the cart details
    console.log("Cart details being passed:", cartDetails); 

    const data = {
      name_customer,
      phone,
      email,
      address,
      comments,
      cartDetails,
      final_total, // Include the formatted cart items
    };
    console.log("new name ")
    console.log(data)
    
    try {
      const response = await fetch('/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), // Send data to backend
      });

      const result = await response.json();

      if (response.ok) {
        onSubmit(data); // Callback on successful submission
      } else {
        console.error('Failed to send email:', result.message);
      }
    } catch (error) {
      console.error('Error during submission:', error);
    }

    onClose(); // Close the modal after submission
  };

  if (!isOpen) {
    return null; // Don't render if the modal is not open
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>Enter your information</h2>
        <div>
          <label htmlFor="name_customer">Name:</label>
          <input
            type="text"
            id="name_customer"
            value={name_customer}
            onChange={(e) => setname_customer(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="phone">Phone:</label>
          <input
            type="text"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="address">Address:</label>
          <input
            type="text"
            id="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="comments">Comments:</label>
          <textarea
            id="comments"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            rows={3} // Text area for comments
          />
        </div>
        <button onClick={handleSubmit}>Submit</button> {/* Submit to send email */}
      </div>
    </div>
  );
};

export default UserInfoModal;
