// src/components/ProductDetail.js
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { products } from '../products';
import { getCartFromLocalStorage } from '../utils';

const ProductDetail = () => {
  const { id } = useParams();
  const product = products.find((p) => p.id === parseInt(id, 10));

  const [selectedImage, setSelectedImage] = useState(product.images[0]);
  const [selectedSize, setSelectedSize] = useState(product.sizes[0]);
  const [selectedPackage, setSelectedPackage] = useState(product.packages[0]);

  const handleImageClick = (image) => {
    setSelectedImage(image); // Update the larger image
  };

  const handleSizeChange = (event) => {
    const newSize = product.sizes.find((s) => s.size === event.target.value);
    setSelectedSize(newSize);
  };

  const handlePackageChange = (event) => {
    const newPackage = product.packages.find((p) => p.type === event.target.value);
    setSelectedPackage(newPackage);
  };

  const calculatePrice = () => {
    const basePrice = product.basePrice;
    const sizeMultiplier = selectedSize.multiplier;
    const packageExtraCost = selectedPackage.extraCost;
    
    // Calculate the total price
    return basePrice * sizeMultiplier + packageExtraCost;
  };

  const addProductToCart = () => {
    const cart = getCartFromLocalStorage();
    const existingProductIndex = cart.findIndex(
      (item) => item.id === product.id 
                && item.size === selectedSize.size 
                && item.package === selectedPackage.type
    );

    if (existingProductIndex >= 0) {
      cart[existingProductIndex].quantity += 1; // Increase quantity
    } else {
      cart.push({
        ...product,
        size: selectedSize.size,
        price: calculatePrice(), // Use calculated price
        package: selectedPackage.type,
        image: selectedImage,
        quantity: 1,
      });
    }

    localStorage.setItem('cart', JSON.stringify(cart));
    alert(`Added ${product.name} (${selectedSize.size}, ${selectedPackage.type}) to cart`);
  };

  return (
    <div className='largecontainer'>
      <div className="product-detail"> {/* Flexbox layout */}
          <div className="product-image-large-wrapper"> {/* Larger image */}
            <img
              src={selectedImage}
              alt={product.name}
              className="product-image-large"
            />
            

          
          </div> <p className='p_discreption_desktop'>
          <h1>{product.name}</h1>
        {product.description}</p>

          
        </div>
      <div className="product-thumbnails"> {/* Thumbnail gallery */}
            {product.images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Thumbnail ${index}`}
                className="product-thumbnail"
                onClick={() => handleImageClick(image)} // Click to change the larger image
              />
            ))}
          </div>
      <div className="product-info-section"> {/* Product info and selections */}
      

        <p className='p_discreption_mobile'><h1>{product.name}</h1>
        {product.description}</p>

        <div className="product-selections"> {/* Size and package selection */}
          <div>
            <label htmlFor="size-select">Select Qty   : </label>
            <select
              id="size-select"
              onChange={handleSizeChange}
              value={selectedSize.size}
            >
              {product.sizes.map((s) => (
                <option key={s.size} value={s.size}>
                  {s.size}
                </option>
              ))}
            </select>
          </div><br/>

          <div>
            <label htmlFor="package-select">Select package  :  </label>
            <select
              id="package-select"
              onChange={handlePackageChange}
              value={selectedPackage.type}
            >
              {product.packages.map((p) => (
                <option key={p.type} value={p.type}>
                  {p.type}
                </option>
              ))}
           
          </select><br/><br/>
          <span className="product-price">Price: ₹{calculatePrice()}</span> {/* Display calculated price */}
          </div>
        </div><br/>

      
        <div className='centerbuttoncart'>
        <button onClick={addProductToCart} className="add-to-cart-button">Add to Cart</button> {/* Add to Cart */}
      </div><br/>
      </div>
    </div>
   
  );
};

export default ProductDetail;
